import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check'
import { getStripePayments, createCheckoutSession, onCurrentUserSubscriptionUpdate } from '@stripe/firestore-stripe-payments'

const firebaseConfig = {
  apiKey: "AIzaSyCLIxstvYEwN7wX3xpJNkVYGukME0byTdo",
  authDomain: "irvotes.firebaseapp.com",
  projectId: "irvotes",
  storageBucket: "irvotes.appspot.com",
  messagingSenderId: "570248263294",
  appId: "1:570248263294:web:7b9789519be65dc0bc8a2f",
  measurementId: "G-FBMW402MTH"
};

const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6Le7BPMoAAAAAHRLZSbbTJArU0t6qwEaBAj1HqvZ'),
  isTokenAutoRefreshEnabled: true
});

getToken(appCheck)
  .then(() => {
    console.log('AppCheck successful.')
  })
  .catch((error) => {
    console.log(error.message)
  })

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app, "australia-southeast1");
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "users",
});

export { auth, analytics, db, storage, functions, payments, createCheckoutSession, onCurrentUserSubscriptionUpdate };