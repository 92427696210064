import React, { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Paper from '@mui/material/Paper'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import ProfileIcon from '../images/ProfileIcon.png'
import ArticlesIcon from '../images/ArticlesIcon.png'
import MyGigCoIcon from '../images/MyGigCoIcon.png'
import AdminIcon from '../images/AdminIcon.png'
import { useAuth } from '../contexts/AuthContext'

export default function BottomNavbar() {

  const ref = useRef(null)
  const { currentUser, customClaimRole, bottomNav, setBottomNav } = useAuth()

  function getPageIndex(route) {
    switch (route) {
      case '/articles': return 0;
      case '/profile': return 1;
      case '/admin': return 2;
      case '/products': return 3;
      default: return 0;
    }
  }

  function GuestNav() {
    if (!currentUser) {
      return (
        <BottomNavigationAction></BottomNavigationAction>
      )
    }
  }

  function UserNav() {
    if (customClaimRole === "user") {
      return (
        <BottomNavigation
          value={bottomNav}
          onChange={(newValue) => {
            setBottomNav(newValue);
          }}
        >
          <BottomNavigationAction
            sx={{ color: '#ffffff' }}
            label="Articles"
            to="/articles"
            component={Link}
            icon={<img src={ArticlesIcon} alt="" height="30vh" />}
          />
          <BottomNavigationAction
            sx={{ color: '#ffffff' }}
            label="Products"
            to="/products"
            component={Link}
            icon={<img src={MyGigCoIcon} alt="" height="30vh" />}
          />
        </BottomNavigation>
      )
    } else {
      return null
    }
  }

  function GigNav() {
    if (customClaimRole === "gig") {
      return (
        <BottomNavigation
          value={bottomNav}
          onChange={(newValue) => {
            setBottomNav(newValue);
          }}
        >
          <BottomNavigationAction
            sx={{ color: '#ffffff' }}
            label="Articles"
            to="/articles"
            component={Link}
            icon={<img src={ArticlesIcon} alt="" height="30vh" />}
          />
          <BottomNavigationAction
            sx={{ color: '#ffffff' }}
            label="Profile"
            to="/profile"
            component={Link}
            icon={<img src={ProfileIcon} alt="" height="30vh" />}
          />
        </BottomNavigation>
      )
    } else {
      return null
    }
  }

  function AdminNav() {
    if (customClaimRole === "admin") {
      return (
        <BottomNavigation
          value={bottomNav}
          onChange={(newValue) => {
            setBottomNav(newValue);
          }}
        >
          <BottomNavigationAction
            sx={{ color: '#ffffff' }}
            label="Articles"
            to="/articles"
            component={Link}
            icon={<img src={ArticlesIcon} alt="" height="30vh" />}
          />
          <BottomNavigationAction
            sx={{ color: '#ffffff' }}
            label="Admin"
            to="/admin"
            component={Link}
            icon={<img src={AdminIcon} alt="" height="30vh" />}
          />
        </BottomNavigation>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setBottomNav(getPageIndex(window.location.pathname))
    console.log("bottomNav: " + bottomNav)

    return () => {
      cancel = true;
    }// eslint-disable-next-line
  }, [window.location.pathname, currentUser, customClaimRole]);

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <GuestNav />
        <UserNav />
        <GigNav />
        <AdminNav />
      </Paper>
    </Box>
  );
}