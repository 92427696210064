import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import WebFont from 'webfontloader'
import Container from '@mui/material/Container'
import TopBar from './components/TopBar'
import BottomNavbar from './components/BottomNavbar'
import LoaderScreen from './components/LoaderScreen'
import Products from './pages/Products'
import Articles from './pages/Articles'
import Auth from './pages/Auth'
import Terms from './pages/Terms'
import LinkEmail from './pages/LinkEmail'
import Profile from './pages/Profile'
import Administrator from './pages/Administrator'
import NotFound from './pages/NotFound'
import Unauthorised from './pages/Unauthorised'
import { useAuth } from './contexts/AuthContext'

export default function App() {

  const { currentUser, customClaimRole } = useAuth()

  WebFont.load({
    custom: {
      families: ['Geomanist Light', 'Geomanist Light Italic', 'Geomanist Regular', 'Geomanist Regular Italic', 'Geomanist Medium', 'Geomanist Bold'],
    },
  });

  useEffect(() => {

  }, [currentUser, customClaimRole]);

  return (
    <>
      <TopBar />
      <Container maxWidth="lg">
        <Container maxWidth="md">
          <Routes>
            {!currentUser && <Route exact path="/" element={<Auth />} />}
            {!currentUser && <Route exact path="/" element={<LoaderScreen />} />}
            {customClaimRole === "user" && <Route exact path="/user" element={<Navigate to="/profile" replace />} />}
            {customClaimRole === "admin" && <Route exact path="/admin" element={<Navigate to="/admin" replace />} />}
            <Route path="/auth" element={<Auth />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/products" element={<Products />} />
            <Route path="/articles" element={<Articles />} />
            {currentUser && <Route path="/linkemail" element={<LinkEmail />} />}
            {customClaimRole === "user" && <Route path="/profile" element={<Profile />} />}
            {customClaimRole === "admin" && <Route path="/admin" element={<Administrator />} />}
            <Route path="/unauthorised" element={<Unauthorised />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </Container>
      <BottomNavbar />
    </>
  )
}
